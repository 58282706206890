import React from "react";
import logo from "../../assets/logo.png";
import logored from "../../assets/logored.png";
import facebook from "../../assets/facebook.png";
import insta from "../../assets/insta.png";
import twitter from "../../assets/twitter.png";
// import linktree from "../../assets/linktree.png";
import beacons from "../../assets/white-beacons.png";
import "./newsletter.css";
import { Button } from "react-bootstrap";

const NewsLetter = () => {
  const handleNotify = async () => {
    window.ml_account("webforms", "5710060", "y3j1a0", "show");
  };
  return (
    <div className="news-letter-page">
      <div className="container">
        <div className="middle-wrapper">
          <div className="logo-section">
            <h6>Site and App Coming Soon!</h6>
            <img src={logo} alt="logo" />
            <div className="social-icon">
              <a
                href="https://beacons.ai/ontargetreader"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img src={beacons} alt="icon1" />
              </a>
              <a
                href="https://www.instagram.com/ontargetreader/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={insta} alt="icon1" />
              </a>
              <a
                href="https://www.facebook.com/OnTargetRomance/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="icon1" />
              </a>

              <a
                href="https://twitter.com/OnTargetReader"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter} alt="icon1" />
              </a>
            </div>
          </div>
          <div className="adventure-section">
            <div>
              <h6>The adventure continues</h6>
            </div>
            <div className="notify-btn">
              <Button onClick={() => handleNotify()}>NOTIFY ME</Button>
            </div>
            <div className="notify-btn">
              <a href="https://ontargetromance.com/" rel="noreferrer"  className="btn btn-primary" target="_blank">
                Join our sister-site, On Target Romance{" "}
                <img src={logored} alt="logo" />{" "}
              </a>
            </div>
          </div>
        </div>
        <div className="footer">
          <p>Copyright On Target Reader LLC 2022</p>
        </div>
      </div>
    </div>
  );
};
export default NewsLetter;
